import gsap from './libs/gsap.min';
import ScrollTrigger from './libs/ScrollTrigger.min';

import {
  widthSize1280,
  //  widthSize768
} from './constants/widthSize';

const impact = document.querySelector('.impact');
const impactTitleWrapper = impact.querySelector('.impact__title-wrapper');
const impactTitleLeft = impact.querySelector('#impact__title-left');
const impactTitleRight = impact.querySelector('#impact__title-right');

let observer;

gsap.registerPlugin(ScrollTrigger);

function animateImpactTitleOnSlides() {
  gsap.to([impactTitleLeft, impactTitleRight], {
    y: (slideIndex) => {
      return slideIndex === 0 ? '50%' : '-50%';
    },
    ease: 'power4.out',
    delay: 0.1,
  });
}

function initObserver() {
  if (window.innerWidth > widthSize1280) {
    observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          impact.classList.contains('slide--current')
        ) {
          animateImpactTitleOnSlides();
          observer.unobserve(impactTitleWrapper);
        }
      },
      { threshold: 1.0 },
    );
    observer.observe(impactTitleWrapper);
  } else {
    return;
  }
}

// function animateImpactTitlesOnScroll() {
//   if (window.innerWidth >= widthSize768 && window.innerWidth <= widthSize1280) {
//     gsap.to([impactTitleLeft, impactTitleRight], {
//       scrollTrigger: {
//         trigger: impact,
//         start: 'top 22%',
//         end: 'top 22%',
//         scrub: 1,
//         reverse: true,
//         ease: 'power4.out',
//       },
//       y: (slideIndex) => {
//         return slideIndex === 0 ? '50%' : '-50%';
//       },
//       ease: 'power1.out',
//     });
//   } else {
//     return;
//   }
// }

window.addEventListener('resize', () => {
  if (window.innerWidth > widthSize1280 && !observer) {
    initObserver();
  } else if (window.innerWidth <= widthSize1280 && observer) {
    observer.unobserve(impactTitleWrapper);
    observer = null;
  }
});

initObserver();
// animateImpactTitlesOnScroll();
